<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.mrpLandingPageInfo.name }}</h1>
      <BannerAid :bannerLink="`media-portal`" />
      <p>{{ this.mrpLandingPageInfo.text }}</p>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <b-container class="tiles">
        <b-row class="center-elements">
          <b-col sm="6" class="search-col mb-5">
            <search-form :i18n="translations.components" />
          </b-col>
        </b-row>
        <b-row class="tile-row">
          <b-col sm="6" xs="12" class="tile-col mb-5" v-for="(obj, index) in computedPrograms" :key="index">
            <card :category="obj.category" :itemName="obj.programName" :itemId="obj.programId"
              :itemDescription="obj.programDescription" :btnText="obj.btnText" :btnUrl="obj.btnUrl"
              :externalUrl="obj.externalUrl" @set_item="setProgramAndRedirect($event)">
              <svg-program-resources class="icon"></svg-program-resources>
            </card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BannerAid from '@/components/BannerAid.vue'
import Card from './CardMRPLp.vue'
import ProgramResources from '@/assets/svgs/program-resources.vue'
import searchForm from './SearchForm.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'
import { transferAuthTokenSecurely } from '@/services/CampMeetingLibraryAuthService.js'

export default {
  name: 'media-resource-portal',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      mrpLPPayload: {
        lang: 'en', // default to english for now
        landingpage: 'MRP_Landing'
      }
    }
  },
  components: {
    BannerAid: BannerAid,
    card: Card,
    SearchForm: searchForm,
    securityBanner: SecurityBanner,
    svgProgramResources: ProgramResources
  },
  methods: {
    ...mapActions({
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
      loadMRPPrograms: 'mrp/loadMRPPrograms',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
      setMRPSelectedCategory: 'mrp/setMRPSelectedCategory'
    }),
    setProgramAndRedirect(data) {
      if (!!data.externalUrl) {
        let extUrl = data.externalUrl.replace('[ind_key]', this.userId)
        // Transfer auth token using PostMessage API to Camp Meeting Library for authentication
        if (data.category === 'ce') {
          transferAuthTokenSecurely(extUrl)
        } else {
          window.open(extUrl, '_blank')
        }
      } else {
        this.setMRPProgramId(data.itemId)
        this.setMRPSelectedCategory(data.category)
        this.$router.push({ name: 'mrp-program' })
      }
    },
    async page_load() {
      this.setLoadingStatus(true)
      this.mrpLPPayload.lang = this.prefCulture
      await this.loadMRPProgramInfo({
        lang: this.prefCulture,
        restype: 'all',
        programid: '92A6E0B1-46CE-49ED-819D-9CA43C8843BA'
      })
      await this.setMRPProgramId('92A6E0B1-46CE-49ED-819D-9CA43C8843BA')
      this.setMRPSelectedCategory('')
      await Promise.all([await this.loadMRPPrograms(this.mrpLPPayload)]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    buildExternalURL(URL) {
      if (!URL) {
        return ''
      }
      if (process.env.NODE_ENV !== 'production') {
        URL = URL.replace('apps.gideons.org', 'apps.staging.gideons.org')
      }
      return URL
    }
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      mrpLandingPageInfo: 'mrp/mrpLandingPageInfo',
      mrpPrograms: 'mrp/mrpPrograms',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId'
    }),
    allowPage() {
      return true
    },
    computedPrograms() {
      let programs = []
      programs = this.mrpPrograms.map(p => {
        return {
          category: p.CategoryCode,
          programId: p.ProgramId,
          programName: p.ProgramName,
          programDescription: p.Description,
          btnUrl: `/resources/media-portal/program/${p.CategoryCode}`,
          btnText: this.mrpLandingPageInfo.buttonText,
          externalUrl: this.buildExternalURL(p.ExternalUrl),
          ResourceSectionEnabled: p.ResourceSectionEnabled
        }
      })
      programs = programs.filter(item => item.ResourceSectionEnabled === '1')
      return programs
    }
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.page_load(),
      this.getComponentTranslations('security-banner', 'mrp-search-form')
    ]).then(results => {
      const componentTranslations = results[2]
      this.$set(this.translations, 'components', componentTranslations)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}

.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;

  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;

    .tile-col-card {
      flex-grow: 2;
    }
  }
}

.tiles {
  margin-bottom: 80px;
}
</style>
